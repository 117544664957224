angular.module('app.resources', ['ngResource']).factory('APIFeed', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/feed",
  {
      _entry: 'instagram'
    });
  }
]).factory('APIEvent', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/eventdata");
  }
]).factory('APIEventdata', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/exhibitor_data.json",
  {
      _private: true,
      role: 'presenter'
    },
  {
      save: {
        method: 'PUT',
        params: {
          _method: 'PUT'
        }
      }
    });
  }
]).factory('APIExhibitors', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/exhibitors/:id",
  {
      id: '@id'
    });
  }
]).factory('APICheckin', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/tickets/:shortcode/:action",
  {
      action: '@action'
    });
  }
]).factory('APIInquiries', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/inquiries.json");
  }
]).factory('APIChats', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/meetings");
  }
]).factory('APIChatsAll', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/meetings/all");
  }
]).factory('APIPublicChats', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/meetings/groups?exhibitor_id=:exhibitor_id",
  {
      exhibitor_id: '@exhibitor_id'
    });
  }
]).factory('APIVisitor', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/visitors.json",
  {
      _visitor: true
    });
  }
]).factory('APIInteractions', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/interactions.json",
  {},
  {
      save: {
        method: 'POST',
        params: {
          _private: true
        }
      }
    });
  }
]).factory('APIConfirm', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/visitors/confirm",
  {
      _visitor: true
    });
  }
]).factory('APITimes', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/matchmaker/:id",
  {
      id: '@id',
      _private: true
    },
  {
      book: {
        method: 'PUT'
      },
      delete: {
        method: 'DELETE'
      }
    });
  }
]).factory('APISpeakers', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/schedule/speakers/:id");
  }
]).factory('APIPrograms', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/schedule");
  }
]).factory('APISlots', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/schedule/slots/:id",
  {
      id: '@id'
    });
  }
]).factory('APICheckEmail', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/visitors/check_email",
  {
      _visitor: true
    });
  }
]).factory('APIFavorites', [
  '$resource',
  function($resource) {
    return $resource("api2.php?shortcode=:shortcode",
  {
      shortcode: '@shortcode'
    });
  }
]).factory('APIReadTable', [
  'Tablify',
  function(Tablify) {
    return Tablify("api3.php");
  }
]).factory('APIRead', [
  '$resource',
  function($resource) {
    return $resource("api3.php");
  }
]).factory('APISettings', [
  '$resource',
  function($resource) {
    return $resource("api4.php");
  }
]).factory('APIActivity', [
  '$resource',
  function($resource) {
    return $resource("api5.php");
  }
]).factory('APIData', [
  '$resource',
  function($resource) {
    return $resource("core/data/data-:slug.json",
  {
      slug: '@slug'
    });
  }
]).factory('APIVideo', [
  '$resource',
  function($resource) {
    return $resource("https://vimeo.com/api/v2/video/:id.json",
  {
      id: '@id'
    });
  }
]).factory('APIStatistics', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/statistics");
  }
]).factory('APITickets', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/visitor/tickets/foreign_tickets",
  {
      auth_ticket: '@auth_ticket',
      _private_all: true
    },
  {
      create: {
        method: 'PUT',
        isArray: true
      },
      delete: {
        method: 'DELETE',
        isArray: true
      }
    });
  }
]).factory('APIScan', [
  '$resource',
  function($resource) {
    return $resource("api.php?path=/:qrcode/scanning",
  {
      auth_token: '@auth_token',
      exhibitor_id: '@exhibitor_id',
      _scan: true
    },
  {
      scan: {
        method: 'PUT'
      }
    });
  }
]).factory('APIMailer', [
  '$resource',
  function($resource) {
    return $resource("core/mailer.php");
  }
]).factory('APIGallery', [
  '$resource',
  function($resource) {
    return $resource("core/gallery.php",
  {
      gallery_id: '@gallery_id'
    });
  }
]);
